
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import lang from "~/mixins/lang.js";
import device from "~/mixins/device.js";
const qs = require("qs");

export default {
  layout: "default",
  name: "Header",
  mixins: [lang, device],
  components: {
    vSelect,
  },
  props: {
    error: {
      type: Object,
      default: () => {
        "error";
      },
    },
    languagesOptions: {
      type: Array,
      default: () => [],
    },
    selectedLanguages: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      topNavigation: {},
      menuActive: null,
      tabActive: 0,
      navIconActive: false,
      selectedLanguage: {},
    };
  },
  async fetch() {
    const query = qs.stringify(
      {
        populate: [
          "topNavigation",
          "topNavigation.subNavigation",
          "topNavigation.tab.tabNavigation",
          "topNavigation.tab.products",
          "topNavigation.tab.products.productImage",
          "topNavigation.tab.button",
        ],
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    try {
      const { data: dataNavigation } = await this.$axios.get(
        `/top-navigation?${query}&locale=${
          this.activeLang && this.activeLang.length > 0
            ? this.activeLang
            : this.browserLang && this.browserLang.length > 0
            ? this.browserLang
            : "en-AE"
        }`,
        {
          headers: {
            "Strapi-Response-Format": "v4",
          },
        }
      );
      this.topNavigation = dataNavigation?.data?.attributes?.topNavigation;
    } catch {}
  },
  computed: {
    queryPath() {
      if (this.pathName != this.$route.path) {
        this.menuActive = null;
      }
      this.pathName = this.$route.path;
    },
  },
  watch: {
    queryPath: function () {},
  },
  methods: {
    handleLanguage(e) {
      this.$emit("handleLanguage", e);
    },
    menuOpen(index) {
      this.menuActive = index;
      this.langOpenStatus = false;
      document.querySelector("body").classList.add("overflow-hidden");
    },
    menuClose() {
      this.menuActive = null;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    selectTab(index) {
      this.tabActive = index;
    },
    navIcon(_e, close = false) {
      const body = document.querySelector("body");
      this.navIconActive = close ? false : !this.navIconActive;
      this.menuActive = null;
      this.tabActive = 0;
      if (body.classList?.value == "" && !close) {
        body.classList?.add("overflow-hidden");
      } else {
        body.classList?.remove("overflow-hidden");
      }
    },
  },
  watch: {
    selectedLanguages() {
      this.selectedLanguage = this.selectedLanguages;
    },
    $route(to, from) {
      const body = document.querySelector("body");
      this.menuActive = null;
      this.navIconActive = false;
      this.menuActive = null;
      this.tabActive = 0;
      this.langOpenStatus = false;
      body.classList.remove("overflow-hidden");
    },
  },
  mounted() {
    if (process.browser) {
      window.addEventListener("scroll", function () {
        const header = document.getElementById("fixed-header");
        if (!header) return;

        const scrollPosition =
          window.pageYOffset || document.documentElement.scrollTop;

        if (scrollPosition > 10) {
          header.style.boxShadow =
            "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)";
        } else {
          header.style.boxShadow = "none";
        }
      });
    }
  },
};
