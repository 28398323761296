import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=c6879f56"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=c6879f56&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/usr/app/components/icon/Icon.vue').default,Picture: require('/usr/app/components/picture/Picture.vue').default,Button: require('/usr/app/components/button/Button.vue').default,Header: require('/usr/app/components/header/Header.vue').default})
